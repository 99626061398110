.dataClient {
    background: white;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    border-radius: 5px;
    width: 100% !important;

    padding: 2px;
    margin: 0px 2px 2px 2px;

    font-size: 14px;
}

.dataClient h4 {
    margin: 2px;
    color: black;
}

.firstRaw {
    margin-top: 0px;
    margin-bottom: 3px;
    text-align: center;
    color: black;
}

.txtCenter {
    text-align: center;
}

.txtCenterRed {
    text-align: center;
    color: red;
}

.bannerButtonLight {
    margin: 0px auto 0 !important;
}

.originRaw {
    margin: 0px 20px 3px 20px;
    text-align: center;
    color: black;
}

.floatright {
    float: right;
}

.textinfos {
    margin-left: 5px;
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
    position: relative;
    top: 6px;
    margin-right: 5px;
}

.searchInput {
    margin-left: 10px;
}

.popUpTarifs {
    width: 70vh;
    min-height: 45vh;
    padding: 30px;
}

.popUpHistoryDelivery {
    width: 100vh;
    min-height: 50vh;
    padding: 30px;
}

/* Popup warning lock client account */
.customUI {
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    height: 174px;
    width: 400px;
}

.customUI h2 {
    margin-top: 5px;
}
.customUI button {
    float: right;
    position: relative;

    font-family: sans-serif;

    margin: 0 0 0 10px;
    background-color: black;
    border: 1px solid black;
    padding: 8px;
    color: white;

    cursor: pointer;
}

.titleWarning {
    display: inline-block;
    margin-left: 5px;
    font-size: 24px;
    font-weight: 600;
}

.warning {
    display: inline-block;
    font-size: 40px !important;
    position: relative;
    top: 6px;
}

.simpleWarning {
    display: inline-block;
    font-size: 30px !important;
    position: relative;
    top: 6px;
    margin-right: 8px;
}

.btnInactif {
    background-color: gray !important;
}

.titleInfo {
    display: inline-block;
    margin-left: 5px;
    font-size: 24px;
    font-weight: 600;
}

.info {
    display: inline-block;
    font-size: 40px !important;
    position: relative;
    top: 11px;
}
