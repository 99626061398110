.mainContent {
    position: relative;
    bottom: 5px;
    background-color: white;
    position: relative;
    bottom: 5px;
    background-color: white;

    padding: 7px 10px;
    padding: 7px 10px;

    font-size: 14px;
    font-family: "ITC Avant Garde Std text";
    font-size: 14px;
    font-family: "ITC Avant Garde Std text";
}

/* Décale lergerment les button vers le bas et ajoute un margin-right */
.adjustPos {
    position: relative;
    top: 6px;
    margin-right: 5px;
    position: relative;
    top: 6px;
    margin-right: 5px;
}

.adjustPos2 {
    position: relative;
    bottom: 6px;
    margin-right: 5px;
    position: relative;
    bottom: 6px;
    margin-right: 5px;
}

.customDatePicker {
    background-color: white;
    position: relative;
    left: 10px;
    background-color: white;
    position: relative;
    left: 10px;
}

.customDatePicker div input {
    font-size: 0.7em !important;
    max-width: 80px;
    height: 0px;
    font-size: 0.7em !important;
    max-width: 80px;
    height: 0px;
}

.fBold {
    position: relative;
    bottom: 0px;
    margin-left: 5px;
    font-weight: 800;
    position: relative;
    bottom: 0px;
    margin-left: 5px;
    font-weight: 800;
}

.email5 {
    margin-top: 15px;
    margin-top: 15px;
}

.inputEmail5 {
    margin-left: 6px;
    width: 280px !important;
    margin-left: 6px;
    width: 280px !important;
}

.changeEmail {
    margin-left: 30px;
    margin-left: 30px;
}

.lineSelect {
    display: flex;
    /* justify-content: space-between; */
    display: flex;
    /* justify-content: space-between; */
}

.labelSelect {
    margin-right: 10px;
    margin-right: 10px;
}

.labelSelectServ {
    margin-left: 40px;
    margin-right: 10px;
    margin-left: 40px;
    margin-right: 10px;
}

.infoTransport {
    margin-right: 40px;
    margin-right: 40px;
}

.inputCarrierSpan {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    position: relative;
    /* width: 60%; */
    width: 170px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    position: relative;
    /* width: 60%; */
    width: 170px;
}

.inputCarrierSpan input {
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 60%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 60%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
}

.inputServiceSpan {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    /* width: 60%; */
    width: 158px;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    /* width: 60%; */
    width: 158px;
}

.inputServiceSpan input {
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 60%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 60%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
}

.checkboxContainer {
    display: flex;
    justify-content: start;
    gap: 20px;
}

.checkboxSaturday {
    margin-left: 42px;
    margin-right: 10px;
}
