.title {
    text-align: center;
    margin-bottom: 2%;
    margin-top: 0;
}

.gridContainer {
    height: 58vh;
    width: 100%;
    background: white;
}

.grey {
    background-color: #f9f9f9;
}

.selectedClient {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.selectMail {
    border: solid 2px black;
    background-size: 100% 200%;
    background-position: top left;
    font-family: inherit;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 14px 20px 9px 20px;
    margin: 0px auto 0;
    cursor: pointer;
    color: black;
    float: right;
}

.selectedClientRight {
    float: right;
    width: 49%;
    padding: 8px 0px 0px 21px !important;
}

.selectedClientLeft {
    float: left;
    width: 49%;
}

.icon:hover {
    cursor: pointer;
}

.delete {
    color: red;
}

.delete:hover {
    cursor: pointer;
}

.lockOrder {
    color: black;
}

.none {
    display: none !important;
}

.customUI {
    border: 1px solid black;
    border-radius: 5px;
    padding: 8px;
    height: 190px;
    width: 500px;
}

.customUI h2 {
    margin-top: 5px;
}
.customUI button {
    float: right;
    position: relative;

    font-family: sans-serif;

    margin: 0 0 0 10px;
    background-color: black;
    border: 1px solid black;
    padding: 8px;
    color: white;

    cursor: pointer;
}

.warning {
    display: inline-block;
    font-size: 40px !important;
    position: relative;
    top: 6px;
}

.beatsWarning {
    display: inline-block;

    color: rgb(3, 3, 3);
    animation: fadeIn linear 2s infinite;
    -webkit-animation: fadeIn linear 2s infinite;
    -moz-animation: fadeIn linear 2s infinite;
    -o-animation: fadeIn linear 2s infinite;
    -ms-animation: fadeIn linear 2s infinite;
}

.grey {
    background-color: #f9f9f9;
}

.clientSelect {
    margin-left: 5px;
}

.legend {
    margin-top: 20px;
    font-style: italic;
    font-weight: 400;
    color: rgb(58, 58, 58);
    font-size: 0.8em;
}

.tableHeader {
}

.lineHeight {
    line-height: 15px;
}

.tableHeaderColDate {
    text-align: center;
    line-height: 15px;
    min-width: 80px;
}

.see {
    color: black;
    cursor: pointer;
}

.refresh {
    display: flex;
    padding: 16px 16px 10px 16px;
    justify-content: flex-end;
    align-items: center;
}

.refreshItem {
    display: flex;
    padding: 5px;
    align-items: center;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
}

.refreshItem:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

.textRefresh {
    margin-left: 8px;
}
