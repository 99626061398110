.paramContainer {
    float: left;
    width: 16%;
    height: 59.8vh;
}

.tableContainer {
    float: right;
    width: 83%;
}

.gridContainer {
    /*  height: 80%; */
    height: 56vh;
    width: 100%;
    background: white;
    font-family: "inherit";
}

.labelArray {
    width: 99.8%;
    /* margin: auto; */
    margin-bottom: 1px;

    border: 1px solid #dbdbdb;
    border-bottom: none;
    padding: 4px 0px;
    /* padding-left: 20px; */

    border-radius: 10px 10px 4px 4px;
    background-color: #eeedeb;
    text-align: center;
}

.customTools {
    border-bottom: 1px solid #d7d7d7;
}

.subTitle {
    margin-top: 4px;
    font-size: 0.8em;
}
.customFieldset {
    height: calc(100% - 20px);
    border-radius: 10px 10px 0px 0px;
    background-color: #eeedeb;
    border: 1px solid #e0e0e0;
}

.customDatePicker {
    background-color: white;
}
.customDatePicker div input {
    font-size: 0.7em !important;
    max-width: 80px;
}

.pLegend {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 5px 10px;
}

.refreshPos_01 {
    margin-left: 20px !important;

    position: relative;
    top: 6px;
}

.txtBold {
    font-weight: 800;
}

.txtDarkGreen {
    color: rgb(23, 70, 35);
}

.lineHeight {
    line-height: 15px;
}
