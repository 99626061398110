.sidebar {
    background-color: black;
    position: absolute;
    top: 0;
    width: 250px;
    min-height: calc(100vh - 30px);
    max-height: 90vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.logo {
    max-width: 45%;
    margin: 25px 0;
}

.link {
    text-align: center;
}

.navList {
    color: white;
}

.navList svg {
    color: white;
}

.navList li:hover {
    border-left: solid 2px white;
}

.version {
    color: white;
    font-size: 10px;
    position: absolute;
    bottom: 10px;
}

.stick {
    position: relative;
    right: 4px;
    bottom: 3px;
}

.under {
    position: relative;
    right: 14px;
    top: 0px;
}

.buttonAdminVisible {
    display: block;
}

.buttonAdminInvisible {
    display: none;
}

.buttonAdmin {
    position: absolute;
    bottom: 30px;
    width: 250px;
}

.boxChangeLogs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 400px;
    /* background-color: 'background.paper'; */
    background-color: white;
    border: none !important;
    border-radius: 5px;
}

.modalLog {
    border: none !important;
}

.boxChangeLogs h4 {
    margin: 0px;
    padding: 10px 14px;
    border-bottom: 1px solid rgb(44, 44, 44);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(218, 217, 216);
    font-size: 1.2em;
}

.listLogs {
    margin: 0px 0px 20px 0px;
    padding: 0px 10px 20px 10px;
    background-color: white;
    height: 100%;
    max-height: 310px;
    overflow-y: auto;

    font-size: 0.8em;
}

.listLogs h1 {
    font-size: 1.2em;
}

.listLogs h2 {
    font-size: 1.1em;
}

.listLogs h3 {
    font-size: 1em;
}

.icoSize01 {
    font-size: 1.3em;
    position: relative;
    left: 2px;
    top: 1px;
}

.popoverRight {
    background-color: rgba(64, 64, 64, 0.9);
    position: absolute;
    margin-left: 215px;
    margin-top: -90px;
}

.listItemSub {
    background-color: red;
    width: 400px;
}

.subMenu_01 {
    background-color: rgb(30, 30, 30) !important;
}
