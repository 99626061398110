.title {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 3%;
}

.gridContainer {
    height: 57vh;
    width: 100%;
    background: white;
}

.tableHeader {
    width: 120px;
    border: 1px solid red;
}
