.searchContainer {
    padding: 6px 10px 0px 10px !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 75%;
    align-items: center;
    flex-direction: column;
}

.searchContainerFacturation {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.searchContainer svg {
    vertical-align: middle;
    color: inherit;
}

.searchIcon {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
}

.title {
    text-align: center;
}

.selectCell {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 3.4375rem;
    align-items: center;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
}

.image {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #eeedf0;
}

.image img {
    position: absolute;
    bottom: 0;
    display: block;
    max-height: 90%;
    margin: auto;
    max-width: 90%;
    top: 0;
    left: 0;
    right: 0;
}

.productDesignation {
    color: black;
    font-size: 13px;
    font-weight: 300;
    padding-bottom: 10px;
    margin-bottom: 0;
    text-align: left;
    letter-spacing: 0.03rem;
}

.ref {
    hyphens: auto;
    margin: 5px 0;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0.02rem;
    /* font-weight: 600; */
    font-weight: 100;
    font-size: smaller;
    color: green;
}

.listWrapper,
.productWrapper {
    padding: 20px;
}
.cartTableRow {
    padding: 20px 0;
    text-align: center;
}

.resultsBox {
    overflow: auto;
    padding-left: 4rem;
    padding-top: 3.5rem;
    width: 100%;
}

.resultsContainer {
    background: white;
    width: 100%;
    position: relative;
    margin-top: 15px;
    display: flex;
    max-height: 60vh;
}

.closeSearch {
    cursor: pointer;
    position: absolute;
    left: 95%;
    top: 3%;
}

.resultsContainer::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: 1%;
    background-color: #fff;
    display: flex;
    width: 0.8125rem;
    height: 0.8125rem;
    transform: rotate(-135deg);
    border-right: 0.0625rem solid #e5e5e5;
    border-bottom: 0.0625rem solid #e5e5e5;
}

.none {
    display: none;
}

.searchInput {
    border: none;
    font-size: inherit;
    outline: none;
    font-family: inherit;
    min-height: 3.4375rem;
    width: 100%;
    border-radius: 0.5rem;
}

.alignCenterLoader {
    text-align: center;
    margin-left: 44%;
    margin-bottom: 20px;
}
