.title {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 3%;
}

.gridContainer {
    height: 50vh;
    width: 100%;
    background: white;
}

.tableHeader {
    width: 120px;
    border: 1px solid red;
}

.btnUpdate {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.underline {
    text-decoration: underline;
    padding-top: 10px;
    padding-bottom: 4px;
}

.container {
    width: 100%;
    background-color: #f9f9f9;
}

.borderGray {
    border: 1px solid rgb(177, 175, 175);
}

.choice {
    padding-left: 7%;
    padding-right: 7%;
}

.selectedClient {
    margin: 20px;
    padding: 20px;
    padding-left: 5%;
    padding-right: 5%;
}

.buttonDuplicate {
    margin: auto;
}

.center {
    font-size: 23px;
    text-align: center;
    padding: 25px;
}

.margin5 {
    margin-top: 5%;
}

.bold {
    font-weight: bold;
}
