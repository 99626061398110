.popUp {
    width: 100%;
    padding-bottom: 16px;
}

.contentLoading {
    width: 80%;
    min-width: 500px;
    min-height: 300px;
}

.title {
    font-size: 23px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}

.contentTable {
    /* background-color: grayLight; */
    width: 100%;
}

.gridContainer {
    height: 69.7vh;
    background: white;
}

.tableHeader {
    font-weight: bold;
}

.unknown svg {
    color: lightgrey;
}

.tableCreditLines {
    position: relative;
    top: 4px;
}
