.title {
    margin-bottom: 22px;
    margin-top: 26px;
    text-align: center;
    width: 100%;
}

.contentBlock {
    /* background-color: grayLight; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    /* background-color: #e0e0e0; */
    background-color: white;
    padding: 1px;
}

.gridContainer {
    margin-top: 16px;
    height: 62vh;
    width: 100%;
    margin: auto;
    background: white;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}

.delivered,
.notDelivered,
.unknown {
    display: flex;
    justify-content: center;
    align-items: center;
}

.delivered svg {
    color: #1ba9aa;
}

.noResult {
    width: 100%;
    text-align: center;
}

.see {
    color: black;
    cursor: pointer;
    font-size: 24px;
}

.buttonDiv {
    display: flex;
    justify-content: space-evenly;
}

.delete {
    color: red;
    cursor: pointer;
}

.noResult svg {
    vertical-align: bottom;
}

.notDelivered svg {
    color: orange;
}

.unknown svg {
    color: lightgrey;
}

.titleClient {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
    font-weight: 600;
}

.orderList {
    padding: 6px 10px 0px 10px !important;
}

.orderList h2 {
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}

.selectedClient {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.selectedClientLeft {
    float: left;
    width: 49%;
}

.selectedClientRight {
    float: right;
    width: 49%;
    padding: 8px 0px 0px 21px !important;
}

.selectMailFacture {
    border: solid 2px black;
    background-size: 100% 200%;
    background-position: top left;
    font-family: inherit;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 14px 20px 9px 20px;
    margin: 0px auto 0;
    cursor: pointer;
    color: black;
    float: right;
}

.tableCreditLines {
    position: relative;
    top: 4px;
}

.lightGray {
    background-color: #f9f9f9;
}

.btnClose {
    border: 1px solid #cbcbcb;
    margin-right: 10px;
    margin-bottom: 10px;
}

.btnClose:hover {
    border: 1px solid #a8aaaa;
    color: black;
}

.border1 {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
}

.clockSearch {
    position: relative;
    bottom: 2px;
    font-size: 20px;
}

.space2 {
    padding: 0 7px;
}

.btnClear {
    font-size: 15px;
    margin-left: 8px;
    font-style: italic;
    text-decoration: underline;
}

.btnClear:hover {
    /* color: #1ba9aa; */
    color: #27b2b2;
    cursor: grab;
}

.eraserIcon {
    position: relative;
    top: 8px;
    margin-left: 4px;
}
