.custom {
    background-color: #28a4e2 !important;
    color: white;
    border-radius: 4px;
}

.content {
    display: flex;
    flex-direction: row;
}

.colorT22 {
    display: inline-block;
    width: 10px;
    height: 12px;
    background-color: #283ae2;
    border: 1px solid white;
    margin-right: 8px;
}

.colorT30 {
    display: inline-block;
    width: 10px;
    height: 12px;
    background-color: #8f28e2;
    border: 1px solid white;
    margin-right: 8px;
}

.colorT31 {
    display: inline-block;
    width: 10px;
    height: 12px;
    background-color: #94b60a;
    border: 1px solid white;
    margin-right: 8px;
}

.colorT32 {
    display: inline-block;
    width: 10px;
    height: 12px;
    background-color: #25732e;
    border: 1px solid white;
    margin-right: 8px;
}

.desc {
    color: white;
}
